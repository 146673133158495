/* eslint-disable @typescript-eslint/no-explicit-any */
import { ElNotification } from 'element-plus'
import { omit } from 'lodash'
import { jwt } from 'shared'

import { AttachmentFile, FileRequestApiOptions } from '@/types'

import { instance } from './api-client'

type PropsType = object | Array<any> | null

const downloadFileBlob = async (
  path: string,
  params: PropsType,
  requestHeaders: PropsType,
  isSecure: boolean,
  fileType: string = 'application/pdf'
) => {
  const headers: any = {
    ...(requestHeaders || {}),
    'Content-Type': 'application/json',
  }
  if (isSecure) {
    const token = await jwt()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
  }

  const response = await instance.request({
    method: 'GET',
    url: path,
    params,
    headers,
    responseType: 'blob',
    paramsSerializer: (params) => {
      return new URLSearchParams(params).toString()
    },
  })

  return new Blob([response.data], { type: fileType })
}

export const handleFileResponse = async (
  path: string,
  params: PropsType,
  requestHeaders: PropsType,
  isSecure: boolean,
  isOpen: boolean,
  saveAs: boolean,
  inside: boolean,
  file: AttachmentFile
) => {
  try {
    const fileType = file?.fileTypeCode
    const blob = await downloadFileBlob(path, params, requestHeaders, isSecure, fileType)
    const url = window.URL.createObjectURL(blob)
    if (isOpen) {
      if (inside) return blob
      window.open(url, '_blank')
      return
    }
    const extension = file.fileName.split('.').pop()
    if (saveAs && (window as any).showSaveFilePicker) {
      try {
        const fileHandle = await (window as any).showSaveFilePicker({
          suggestedName: file.fileName,
          types: [
            {
              description: 'Files',
              accept: {
                [fileType]: [`.${extension}`],
              },
            },
          ],
        })
        const writable = await fileHandle.createWritable()
        await writable.write(blob)
        await writable.close()
      } catch (err) {
        console.error('Save As dialog failed:', err)
      }
      return
    }
    const link = document.createElement('a')
    link.href = url
    link.setAttribute(
      'download',
      file.fileName.endsWith(`.${extension}`) ? file.fileName : `${file.fileName}.${extension}`
    )
    document.body.appendChild(link)
    link.click()
    if (link.parentNode) link.parentNode.removeChild(link)
  } catch (error) {
    const errorMessage = error?.response?.data?.message ?? 'Unknown error occurred'
    ElNotification({
      title: 'Error',
      message: errorMessage,
      type: 'error',
      position: 'top-left',
    })
    throw error
  }
}

export const makeDownloadableUrl = async (
  path: string,
  params: PropsType,
  requestHeaders: PropsType,
  isSecure: boolean
) => {
  try {
    const blob = await downloadFileBlob(path, params, requestHeaders, isSecure)
    return window.URL.createObjectURL(blob)
  } catch (error) {
    console.error('Error while making downloadable URL:', error)
  }
}

const downloadFileBlobForPOST = async (
  path: string,
  params: PropsType,
  options: FileRequestApiOptions
): Promise<Blob> => {
  const headers: any = {
    ...(options.requestHeaders || {}),
    'Content-Type': 'application/json',
  }
  if (options.isSecure === undefined || options.isSecure) {
    const token = await jwt()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
  }

  const response = await instance.request({
    method: 'POST',
    url: path,
    data: params, // Send params in the request body
    headers,
    responseType: 'blob',
    timeout: options.timeout || 60000,
    ...omit(options, ['requestHeaders', 'isSecure']),
    onDownloadProgress: options.onProgress,
  })

  return new Blob([response.data], { type: options.fileType || 'text/csv' })
}

export const downloadFileExcel = async (
  path: string,
  params: PropsType,
  options: FileRequestApiOptions
) => {
  const blob = await downloadFileBlobForPOST(path, params, {
    requestHeaders: options.requestHeaders,
    isSecure: options.isSecure,
    fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${options.fileName}.xlsx`)
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}
